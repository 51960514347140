
import React, { useState, useEffect, useMemo } from "react";

import "./Home.css";

import "./MapDashboard.css";
import Slider from "react-slick";
import { Button, Drawer, TextField, Typography, Box ,Grid,CircularProgress} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import "./Dashboard.css";
const images = [
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FirstStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/SecondStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/ThirdStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FourthStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FifthStep.svg"
];
const imageStyle = {
  width: '350px', 
};
const imagesCarousel = [
  {
    src: require("../../src/assets/gcrs_images/updates/cityChampions.png"),
    alt: "invested",
    link: "https://twitter.com/gailindia/status/1123883594844393473",
  },
  {
    src: require("../../src/assets/gcrs_images/updates/cityChampions.png"),
    alt: "member",
    
  },
  {
    src: require("../../src/assets/gcrs_images/updates/cityChampions.png"),
    alt: "invested",
    link: "https://www.thehindu.com/news/cities/Visakhapatnam/gail-gives-wings-to-city-startup/article26947155.ece",
  },
  {
    src: require("../../src/assets/gcrs_images/updates/cityChampions.png"),
    alt: "member",
    link: "https://www.fairtradenapp.org/collaboration-with-geo-climate-risk-solutions-pvt-ltd-climate-change-impact-and-ghg-emissions-in-4-tea-estates-and-providing-recommendations-for-adaptation-and-mitigation/",
  },
 
];

const settings = {
  dots: true,
  infinite: true,
  speed: 600,
  slidesToShow: 1, 
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true, 
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [showBar, setShowbar] = useState(true);
  const handleToggleDetails = () => {
    setShowbar(!showBar);
  };
  //CHECKED BOx
  const [selectedBoxes, setSelectedBoxes] = useState({
    Risk: true,
    Water: true,
    Carbon: true,
    Groundwater: true,
    Carbonstock: true,
  });
 
  //handle SELECTDE BOX
const [selectedOptions, setSelectedOptions] = useState([]);
const handleCheckboxChange = (updatedCheckboxes) => {
setSelectedBoxes(updatedCheckboxes);
   

// Filter selected categories and update the OpenLayers map
const selectedCategories = Object.keys(updatedCheckboxes).filter(
  (category) => updatedCheckboxes[category]
 );
  setSelectedOptions(selectedCategories);
};
const [isDrawerOpen, setDrawerOpen] = useState(false);

const toggleDrawer = (open) => () => {
  setDrawerOpen(open);
};

const imagePaths = [
  "Rectangle 47.png",
  "Rectangle 48.png",
  // "Rectangle 49.png",
  "Rectangle 50.png",
  "Rectangle 51.png",
  "Rectangle 52.png",
  "Rectangle 54.png",
  "Rectangle 55.png",
  "Rectangle 56.png",
  "Rectangle 57.png",
  "Rectangle 58.png",
  "Rectangle 59.png",
  "Rectangle 60.png",
  "Rectangle 61.png",
  "Rectangle 62.png",
  "Rectangle 63.png",
  "Rectangle 64.png",
  "Rectangle 71.png",
  "Rectangle 72.png",
  "Rectangle 73.png",
  "Rectangle 74.png",
  "Rectangle 75.png",
  "Rectangle 76.png",
  "Rectangle 77.png",
  "Rectangle 78.png",
  "Rectangle 79.png",
  "Rectangle 80.png",
  "Rectangle 81.png",
  "Rectangle 82.png",
  "Rectangle 83.png",
  "Rectangle 84.png",
  "Rectangle 85.png",
  "Rectangle 86.png",
  "Rectangle 87.png",
  "Rectangle 88.png",
  "Rectangle 95.png",
  "Rectangle 96.png",
  "Rectangle 97.png",
  "Rectangle 98.png",
  "Rectangle 99.png",
  "Rectangle 100.png",
  "Rectangle 101.png",
  "Rectangle 102.png",
  "Rectangle 103.png",
  "Rectangle 104.png",
  "Rectangle 105.png",
  "Rectangle 106.png",
  "Rectangle 89.png",
  "Rectangle 90.png",
  "Rectangle 91.png",
  "Rectangle 92.png",
  "Rectangle 93.png",
  "Rectangle 94.png",
  "Rectangle 107.png",
  "Rectangle 108.png",
  "Rectangle 109.png",
  "Rectangle 110.png",
  "Dept of Environment & Remote Sensing_j&k.png",
];
const checkCategory = useMemo(
    () => ({
      Risk: {
        category: "Risk Assessment",
        color: "red",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/RiskAssesment.svg")
          .default,
      },
      Water: {
        category: "Water Stewardship",
        color: "blue",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/WaterStewardship.svg")
          .default,
      },
      Carbon: {
        category: "Carbon Offsetting",
        color: "green",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/CO2.svg")
          .default,
      },
      Groundwater: {
        category: "Groundwater",
        color: "orange",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/Groundwater.svg")
          .default,
      },
      Carbonstock: {
        category: "Carbon Stock Assessment",
        color: "purple",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/CarbonstockAssesmenmt.svg")
          .default,
      },
    }),
    []
  );
  // Initialize selectedOptions with the initially checked categories
  useEffect(() => {
    const initialSelectedCategories = Object.keys(selectedBoxes).filter(
      (category) => selectedBoxes[category]
    );
    setSelectedOptions(initialSelectedCategories);
  }, []);

  const [isClicked1] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);

  const [isClicked2] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const [isClicked3] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const [isClicked4] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  const [isClicked5] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);


  const [,setIsHovered6] = useState(false);

  const normalImageSrc1 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Screening.svg').default];

  const normalImageSrc2 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Scientific.svg').default];

  const normalImageSrc3 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Design.svg').default];
 
  const normalImageSrc4 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Restoration.svg').default];
 
  const normalImageSrc5 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Monitoring.svg').default];


  // const products = [
  //   {
  //     id: 1,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/lamas.png",
  //     alt: "lamas",
  //     description:
  //       "Delivers precise diagnostics of lacustrine systems and offers predictive analytics for lake health, encroachment detection, and water positivity, facilitating data-driven environmental solutions.",
  //     link: "https://lamas.co.in/",
  //   },
  //   {
  //     id: 2,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ce.png",
  //     alt: "ce",
  //     description:
  //       "A carbon footprint management platform that facilitates carbon credits trading and supports net-zero commitments through transparent carbon accounting aligned with ESG reporting frameworks.",
  //     link: "https://carbonexchange.co.in/",
  //   },
  //   {
  //     id: 3,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/geosust.png",
  //     alt: "geosust",
  //     description:
  //       "Provides capability for geohazard/natural hazard risk identification and continuous monitoring, fostering proactive risk mitigation measures.",
  //     link: "https://geosust.com/",
  //   },
  //   {
  //     id: 4,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/geocoast.png",
  //     alt: "geocoast",
  //     description:
  //       "Provides coastal zone deterioration tracking and oil spill impact assessment, facilitating data-driven strategy implementation for coastal resilience and alignment with ESG investment priorities.",
  //   },
  // ];

  const handleCardMouseEnter = (index) => {
    switch (index) {
      case 1:
        setIsHovered1(true);
        break;
      case 2:
        setIsHovered2(true);
        break;
      case 3:
        setIsHovered3(true);
        break;
      case 4:
        setIsHovered4(true);
        break;
      case 5:
        setIsHovered5(true);
        break;
      case 6:
        setIsHovered6(true);
        break;
      default:
        break;
    }
  };

  const handleCardMouseLeave = (index) => {
    switch (index) {
      case 1:
        setIsHovered1(false);
        break;
      case 2:
        setIsHovered2(false);
        break;
      case 3:
        setIsHovered3(false);
        break;
      case 4:
        setIsHovered4(false);
        break;
      case 5:
        setIsHovered5(false);
        break;
      case 6:
        setIsHovered6(false);
        break;
      default:
        break;
    }
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (currentImageIndex < images.length - 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => prevIndex + 1);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [currentImageIndex]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    organisation: '',
    role: '',
    query: ''
  });

  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    organisation: '',
    role: '',
    query: '',
  };
 
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
const [responseError, setResponseError] = useState('');


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };
  const validateForm = () => {
    const newErrors = {};
    

    const phoneRegex = /^[0-9]{10,15}$/;
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Phone number must be between 10 and 15 digits.';
    }

    
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      }
    });

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      setResponseMessage(''); 
      setResponseError('');
  
      try {
        const payload = {
          data: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            organisation: formData.organisation,
            role: formData.role,
            query: formData.query,
          },
        };
  
        const response = await fetch('https://gcrs.co.in/apis/record_query/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const resp = await response.json();
  
        if (resp.status === 'success') {
          setResponseMessage('Your query has been submitted successfully.');
          setFormData(initialFormData); 
        } else {
          setResponseError(`Error: ${resp.error}`);
        }
      } catch (error) {
        setResponseError(`Error: ${error.message}`);
      } finally {
        setLoading(false);
  
        
        setTimeout(() => {
          setResponseMessage('');
          setResponseError('');
        }, 3000);
      }
    }
  };
  
  

  return (
    <>
   <div className="banner-home">
      <div>
        <a href="#scroll-section" style={{ textDecoration: "none" }}>
          <p className="scroll-styles">SCROLL</p>
        </a>
      </div>
      <div>
      <h1 style={{marginLeft:'10px'}} className="main-heading">
      we restore the degraded landscapes for sustainable living and thriving businesses
     </h1>
     <div className="images-container">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="step"
              className={`image-alignment ${index === currentImageIndex ? 'visible' : ''} ${index === 4 ? 'fifth-image' : ''}`}
            />
          ))}
          <img
            src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/5_steps_vertical1.svg"
            alt="mobile step"
            className="mobile-image"
           
          />
        </div>
        <div style={{display:'flex',flexDirection:'row',marginTop:'200px'}}>
       <div>
          <a href="/ContactUs" className="contact-button">Contact Us</a>
        </div>
        <div >
       
        <a onClick={toggleDrawer(true)} className="contact-button" style={{ cursor: 'pointer' }}>
        Send Your Request
        </a>
      </div> 
       </div>
       <Drawer
  anchor="right"
  open={isDrawerOpen}
  onClose={toggleDrawer(false)}
  sx={{
    width: { xs: '90vw', sm: '75vw', md: 600 },
    maxWidth: 600,
  }}
>
<Box
  sx={{
    padding: { xs: 2, sm: 3 },
    width: { xs: '90vw', sm: '80vw', md: 600 }, 
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    position: 'relative',
    alignItems: 'center',
    borderRadius: '8px',
  }}
>

    <IconButton
      onClick={toggleDrawer(false)}
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
      }}
    >
      <CloseIcon />
    </IconButton>
    <Typography
      sx={{ mb: 2, fontWeight: 'bold', fontSize: 24, textAlign: 'center', color: '#333' }}
    >
      Query/Requirement
    </Typography>

    <Grid container spacing={2}>
    <Grid item xs={12}>
          <TextField
            label="Name"
            name="name"
            type="name"
            placeholder="Enter Your Name"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            variant="outlined"
            error={!!formErrors.name}
            helperText={formErrors.name}
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '4px',
                borderColor: 'rgba(0, 0, 0, 0.2)',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.2)', 
              },
              '& .MuiFormLabel-root': {
                fontSize: '0.9rem', 
                color: 'rgba(0, 0, 0, 0.4)',
              },
              '& .MuiFormLabel-root.Mui-focused': {
                color: 'rgba(0, 0, 0, 0.6)', 
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.3)',
              },
            }}
          />

          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              error={!!formErrors.email}
              helperText={formErrors.email} 
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '4px',
                  borderColor: 'rgba(0, 0, 0, 0.2)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.2)', 
                },
                '& .MuiFormLabel-root': {
                  fontSize: '0.9rem', 
                  color: 'rgba(0, 0, 0, 0.4)',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: 'rgba(0, 0, 0, 0.6)', 
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone"
              name="phone"
              type="tel"
              placeholder="Enter Your Phone Number"
              value={formData.phone}
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              error={!!formErrors.phone}
              helperText={formErrors.phone}
              inputProps={{
                maxLength: 10,
              }}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '4px',
                  borderColor: 'rgba(0, 0, 0, 0.2)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.2)', 
                },
                '& .MuiFormLabel-root': {
                  fontSize: '0.9rem', 
                  color: 'rgba(0, 0, 0, 0.4)',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: 'rgba(0, 0, 0, 0.6)', 
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
             <TextField
              label="Organization"
              name="organisation"
              type="organisation"
              placeholder="Enter Your Organization"
              value={formData.organisation}
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              error={!!formErrors.organisation} 
              helperText={formErrors.organisation} 
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '4px',
                  borderColor: 'rgba(0, 0, 0, 0.2)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.2)', 
                },
                '& .MuiFormLabel-root': {
                  fontSize: '0.9rem', 
                  color: 'rgba(0, 0, 0, 0.4)',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: 'rgba(0, 0, 0, 0.6)', 
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
          <TextField
              label="Role"
              name="role"
              type="role"
              placeholder="Enter Your Role"
              value={formData.role}
              onChange={handleChange}
              required
              fullWidth
              variant="outlined"
              error={!!formErrors.role}
              helperText={formErrors.role} 
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '4px',
                  borderColor: 'rgba(0, 0, 0, 0.2)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.2)', 
                },
                '& .MuiFormLabel-root': {
                  fontSize: '0.9rem', 
                  color: 'rgba(0, 0, 0, 0.4)',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: 'rgba(0, 0, 0, 0.6)', 
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
            />
          </Grid>
      <Grid item xs={12}>
        <TextField
          label="Query"
          name="query"
          placeholder="Enter Your Query"
          value={formData.query}
          onChange={handleChange}
          multiline
          rows={4}
          required
          fullWidth
          variant="outlined"
          error={!!formErrors.query}
          helperText={formErrors.query}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: '4px',
              borderColor: 'rgba(0, 0, 0, 0.2)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.2)',
            },
            '& .MuiFormLabel-root': {
              fontSize: '0.9rem',
              color: 'rgba(0, 0, 0, 0.4)',
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.3)',
            },
          }}
        />
      </Grid>
    </Grid>

    <Button
      variant="contained"
      color="success"
      onClick={handleSubmit}
      sx={{
        width: '90%',
        marginTop: 2,
        backgroundColor: '#4caf50',
        '&:hover': {
          backgroundColor: '#388e3c',
        },
      }}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress size={24} sx={{ color: 'white' }} />
      ) : (
        'Submit'
      )}
    </Button>

    {responseMessage && (
      <Typography variant="body1" sx={{ color: 'green', mt: 2 }}>
        {responseMessage}
      </Typography>
    )}

    {responseError && (
      <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
        {responseError}
      </Typography>
    )}
     <Typography
      variant="h6"
      sx={{ textAlign: 'center', mt: 0, fontWeight: 'bold' }}
    >
      Contact Us
    </Typography>
    <Typography
      variant="body1"
      sx={{ textAlign: 'center', mt: 0, color: '#333' }}
    >
      Email: <a href="mailto:business@gcrs.co.in">business@gcrs.co.in</a>
    </Typography>

    
    <Box>
  <Typography
    sx={{ textAlign: 'left', mt: 1, fontWeight: 'bold', fontStyle: 'italic' }}  
  >
    Disclaimer
  </Typography>
  
  <Typography
    variant="body2"
    sx={{ mt: 1, textAlign: 'left' ,color:'#a2a2a2'}}  
  >
    By submitting this form, you agree to our Privacy Policy and consent to the processing of your personal data in order to receive information related to our services. We respect your privacy and are committed to protecting your personal information. Your data will not be shared with third parties without your consent, except as required by law.
  </Typography>
</Box>


   
  </Box>
</Drawer>

  
      </div>
    </div>

    <div id="scroll-section" className="map-main-container">
  <div>
    <h2
      style={{
        color: "#bd0324",
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
        paddingTop: "1.5rem",
      }}
    >
       Who Are We
    </h2>
    <h2
      style={{
        fontSize: "20px",
        textAlign: "center",
        padding: "20px",
        marginTop: "20px",
        lineHeight: "1.5",
      }}
    >
      At GCRS, we stand at the forefront of innovation and expertise, dedicated to tackling the complex challenges of environmental sustainability.
      Our expert team not only offers exceptional consultancy and advisory services but also develops cutting-edge solutions and tools tailored to the unique needs of our diverse clientele. This includes government bodies, corporations, academic and research institutions, and a range of organisations committed to global sustainability, such as multi-lateral and bilateral funding partners, donor agencies, and non-governmental organisations.
    </h2>
    <h2
      style={{
        color: "#bd0324",
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
      }}
    >
      Our Approach
    </h2>
    <div className="row justify-content-center">
      {/* Card 1 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked1 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(1)}
          onMouseLeave={() => handleCardMouseLeave(1)}
        >
          <div className={`circle ${isHovered1 ? "hovered" : ""}`}>
            <img
              src={isClicked1 ? isHovered1 : normalImageSrc1}
              style={{ width: "50px" }}
              alt="Climate & Natural Hazard Risk Analytics"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",position: "relative",display: "inline-block"}}className="hover-effect-home">
          <span style={{fontWeight: "bold",fontSize: "16px"}}> Screening & Diagnosing</span> the Health of landscapes
          </h3>
        </div>
      </div>

      {/* Card 2 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked2 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(2)}
          onMouseLeave={() => handleCardMouseLeave(2)}
        >
          <div className={`circle ${isHovered2 ? "hovered" : ""}`}>
            <img
              src={isClicked2 ? isHovered2 : normalImageSrc2}
              style={{ width: "50px" }}
              alt="Environmental Impact & Sustainability Analytics"
              className="white-card"
            />
          </div>
          <h3
  style={{
    fontSize: "14px",
    padding: "10px",
    textAlign: "center",
    paddingTop: "10px",
    display: "inline-block", 
    whiteSpace: "nowrap"     
  }}
  className="hover-effect-home"
>
  Detailed <span style={{ fontWeight: "bold", fontSize: "16px" }}>Scientific Assessment</span>
</h3>


          </div>
      </div>

      {/* Card 3 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked3 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(3)}
          onMouseLeave={() => handleCardMouseLeave(3)}
        >
          <div className={`circle ${isHovered3 ? "hovered" : ""}`}>
            <img
              src={isClicked3 ? isHovered3 : normalImageSrc3}
              style={{ width: "50px" }}
              alt="Ground Water Impact Assessment"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
           <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Design</span> Restoration & Management Plans
          </h3>
        </div>
      </div>

      {/* Card 4 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked4 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(4)}
          onMouseLeave={() => handleCardMouseLeave(4)}
        >
          <div className={`circle ${isHovered4 ? "hovered" : ""}`}>
            <img
              src={isClicked4 ? isHovered4 : normalImageSrc4}
              style={{ width: "50px" }}
              alt="Card 4"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
           <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Restoration</span> of Landscapes
          </h3>
          </div>
      </div>

      {/* Card 5 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked5 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(5)}
          onMouseLeave={() => handleCardMouseLeave(5)}
        >
          <div className={`circle ${isHovered5 ? "hovered" : ""}`}>
            <img
              src={isClicked5 ? isHovered5 : normalImageSrc5}
              style={{ width: "50px" }}
              alt="Geospatial Tech Integrations"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
             Continuous <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Monitoring</span> of Landscapes
          </h3>

        </div>
      </div>
    </div>
    <div>
                {/* <h2
                  style={{
                    color: "#BD0324",
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  our solutions
                </h2>
                <div className="image-container">
                  <div className="row justify-content-center">
                    {products.map((product) => (
                      <div
                        key={product.id}
                        className="col-lg-3 col-md-3 col-sm-6 mb-4"
                        
                      >
                        <div
                          style={{
                            height: "250px",
                            width: "100%",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <a className="fusion-no-lightbox" href={product.link}>
                            <img
                              decoding="async"
                              src={product.imageSrc}
                              alt={product.alt}
                              style={{
                                width: "14.375rem",
                                height: "5rem",
                                
                              }}
                              className="img-fluid"
                            />
                          </a>
                          <p
                            style={{
                              color: "#282222",
                              fontSize: "0.875rem",

                              fontWeight: "bold",
                              
                             
                            }}
                          >
                            {product.description}
                          </p>
                          {product.link && (
                            <a
                              href={product.link}
                              id="accred_link"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{}}
                            >
                              ({product.link})
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
    
     
        <h1
          className="dashboard-text"
          style={{
            color: "#bd0324",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
           
          }}
        >
          Updates/News
        </h1>
     

      
    </div>
  </div>
  <div className="carousel-container">
      <Slider {...settings}>
        <div className="carousel-card">
         
          <div className="carousel-content">
          <img
            src={require("../../src/assets/gcrs_images/partners_investers/city_champions.png")}
            alt="Image 1"
            style={{width:'40%'}}
            
          />
           <div style={{marginLeft:'20px'}}>
            <p style={{color:'#000000',width:'95%',marginTop:'70px'}}>GCRS honored as one of the Top 16 City Champions for 2024, an initiative to champion, support, and mentor visionary organizations committed to shaping the future of our cities. Supported by Josh Talks, Omidyar Network India, and IIM Calcutta.</p>
           </div>
          </div>
        </div>
        <div className="carousel-card">
         
          <div className="carousel-content">
          <img
            src={require("../../src/assets/gcrs_images/partners_investers/amruth2.0.png")}
            alt="Image 1"
            style={{width:'40%'}}
            
          />
           <div style={{marginLeft:'10px'}}>
           {/* <h2 style={{color:'#bd0324',fontSize:'20px'}}>GCRS is One of the Top 16 City Champions for 2024</h2> */}
            <p style={{color:'#000000',width:'95%',marginTop:'90px'}}>GCRS is the winner of India Water Pitch-Pilot-Scale Challenge, organized by Ministry of Housing and Urban Affairs, under AMRUT 2.0.
            </p>
            {/* <a href="#" className="read-more">Read more</a> */}
           </div>
          </div>
        </div>
        <div className="carousel-card">
         
         <div className="carousel-content">
         <img
           src={require("../../src/assets/gcrs_images/partners_investers/stanford2.png")}
           alt="Image 1"
           
           
         />
         <div style={{marginLeft:'20px'}}>
         {/* <h2 style={{color:'#bd0324',fontSize:'20px'}}>GCRS is One of the Top 16 City Champions for 2024</h2> */}
           <p style={{color:'#000000',width:'95%',marginTop:'70px'}}>GCRS successfully implemented its flagship Lake Management System (LAMAS) in the historic city of Ayodhya.
           </p>
           {/* <a href="#" className="read-more">Read more</a> */}
         </div>
         </div>
       </div>
        <div className="carousel-card">
         
          <div className="carousel-content">
          <img
            src={require("../../src/assets/gcrs_images/partners_investers/seed2.png")}
            alt="Image 1"
           
          />
            <div style={{marginLeft:'20px'}}>
            {/* <h2 style={{color:'#bd0324',fontSize:'20px'}}>GCRS is One of the Top 16 City Champions for 2024</h2> */}
            <p style={{color:'#000000',width:'95%',marginTop:'70px'}}>GCRS selected for the 2024 cohort of the prestigious Stanford Seed Transformation Program.</p>
            {/* <a href="#" className="read-more">Read more</a> */}
            </div>
          </div>
        </div>
      </Slider>
    </div>
    </div>
    <h1
          className="dashboard-text"
          style={{
            color: "#bd0324",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            marginTop:'50px'
          }}
        >
        Partners & Investors
        </h1>
        <div className="image-row" style={{ 
  display: 'flex', 
  flexWrap: 'wrap', 
  justifyContent: 'flex-start',  
  alignItems: 'center', 
  gap: '15px', 
  rowGap: '20px', 
  margin: '0 auto',  
  width: '80%', 
  maxWidth: '1200px',  
  marginTop: '32px', 
}}>
  <img src={require("../../src/assets/Clients/Gail.png")} alt="Gail" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/Clients/Synspective.png")} alt="Synspective" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/Clients/JBA1.png")} alt="JBA1" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/Clients/clients1.png")} alt="clients1" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/Clients/JBA.png")} alt="JBA" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/Clients/clients2.png")} alt="clients2" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/Amrut.svg").default} alt="Amrut" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/state_wetland.svg").default} alt="State Wetland" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/cgwa.svg").default} alt="CGWA" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/member_of_aws.svg").default} alt="AWS Member" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/city_champions.svg").default} alt="City Champions" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/member_of_iah.svg").default} alt="IAH Member" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/iwa.svg").default} alt="IWA" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/R&D.svg").default} alt="R&D" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
  <img src={require("../../src/assets/gcrs_images/partners_investers/member_of_hs.svg").default} alt="HS Member" className="partner-image" style={{ flexBasis: 'calc(20% - 15px)' }} />
</div>


    <h1
          className="dashboard-text"
          style={{
            color: "#bd0324",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            marginTop:'50px'
          }}
        >
        Clients
        </h1>
      
     

      {imagePaths
        .reduce((rows, path, index) => {
          const rowIndex = Math.floor(index / 6);
          if (!rows[rowIndex]) {
            rows[rowIndex] = [];
          }
          rows[rowIndex].push(
            <img
              src={require(`../../src/assets/Clients/${path}`)}
              style={{ margin: "1.5rem" }}
              key={index}
              alt={`Client ${index + 1}`}
            />
          );
          return rows;
        }, [])
        .map((row, index) => (
          <div className="image-row" key={index}>
            {row}
          </div>
        ))}

    </>
  );
};
export default Home;



// import React, { useState, useEffect, useMemo } from "react";

// import "./Home.css";
// import OpenLayersMap from "./MapDashboard";
// import SidebarDashboard from "./SidebarDashboard";
// import "./MapDashboard.css";

// import "./Dashboard.css";
// const images = [
//   "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FirstStep.svg",
//   "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/SecondStep.svg",
//   "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/ThirdStep.svg",
//   "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FourthStep.svg",
//   "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FifthStep.svg"
// ];


// const Home = () => {
//   const [showBar, setShowbar] = useState(true);
//   const handleToggleDetails = () => {
//     setShowbar(!showBar);
//   };

//   //CHECKED BOx
//   const [selectedBoxes, setSelectedBoxes] = useState({
//     Risk: true,
//     Water: true,
//     Carbon: true,
//     Groundwater: true,
//     Carbonstock: true,
//   });
 
//   //handle SELECTDE BOX
// const [selectedOptions, setSelectedOptions] = useState([]);
// const handleCheckboxChange = (updatedCheckboxes) => {
// setSelectedBoxes(updatedCheckboxes);
   

// // Filter selected categories and update the OpenLayers map
// const selectedCategories = Object.keys(updatedCheckboxes).filter(
//   (category) => updatedCheckboxes[category]
//  );
//   setSelectedOptions(selectedCategories);
// };

// const checkCategory = useMemo(
//     () => ({
//       Risk: {
//         category: "Risk Assessment",
//         color: "red",
//         path: require("../../src/assets/gcrs_images/dashBoard_icons/RiskAssesment.svg")
//           .default,
//       },
//       Water: {
//         category: "Water Stewardship",
//         color: "blue",
//         path: require("../../src/assets/gcrs_images/dashBoard_icons/WaterStewardship.svg")
//           .default,
//       },
//       Carbon: {
//         category: "Carbon Offsetting",
//         color: "green",
//         path: require("../../src/assets/gcrs_images/dashBoard_icons/CO2.svg")
//           .default,
//       },
//       Groundwater: {
//         category: "Groundwater",
//         color: "orange",
//         path: require("../../src/assets/gcrs_images/dashBoard_icons/Groundwater.svg")
//           .default,
//       },
//       Carbonstock: {
//         category: "Carbon Stock Assessment",
//         color: "purple",
//         path: require("../../src/assets/gcrs_images/dashBoard_icons/CarbonstockAssesmenmt.svg")
//           .default,
//       },
//     }),
//     []
//   );
//   // Initialize selectedOptions with the initially checked categories
//   useEffect(() => {
//     const initialSelectedCategories = Object.keys(selectedBoxes).filter(
//       (category) => selectedBoxes[category]
//     );
//     setSelectedOptions(initialSelectedCategories);
//   }, []);

//   const [isClicked1] = useState(false);
//   const [isHovered1, setIsHovered1] = useState(false);

//   const [isClicked2] = useState(false);
//   const [isHovered2, setIsHovered2] = useState(false);

//   const [isClicked3] = useState(false);
//   const [isHovered3, setIsHovered3] = useState(false);

//   const [isClicked4] = useState(false);
//   const [isHovered4, setIsHovered4] = useState(false);

//   const [isClicked5] = useState(false);
//   const [isHovered5, setIsHovered5] = useState(false);


//   const [,setIsHovered6] = useState(false);

//   const normalImageSrc1 =
//   [require('../../src/assets/gcrs_images/5_steps_redIcons/Screening.svg').default];

//   const normalImageSrc2 =
//   [require('../../src/assets/gcrs_images/5_steps_redIcons/Scientific.svg').default];

//   const normalImageSrc3 =
//   [require('../../src/assets/gcrs_images/5_steps_redIcons/Design.svg').default];
 
//   const normalImageSrc4 =
//   [require('../../src/assets/gcrs_images/5_steps_redIcons/Restoration.svg').default];
 
//   const normalImageSrc5 =
//   [require('../../src/assets/gcrs_images/5_steps_redIcons/Monitoring.svg').default];


//   // const products = [
//   //   {
//   //     id: 1,
//   //     imageSrc:
//   //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/lamas.png",
//   //     alt: "lamas",
//   //     description:
//   //       "Delivers precise diagnostics of lacustrine systems and offers predictive analytics for lake health, encroachment detection, and water positivity, facilitating data-driven environmental solutions.",
//   //     link: "https://lamas.co.in/",
//   //   },
//   //   {
//   //     id: 2,
//   //     imageSrc:
//   //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ce.png",
//   //     alt: "ce",
//   //     description:
//   //       "A carbon footprint management platform that facilitates carbon credits trading and supports net-zero commitments through transparent carbon accounting aligned with ESG reporting frameworks.",
//   //     link: "https://carbonexchange.co.in/",
//   //   },
//   //   {
//   //     id: 3,
//   //     imageSrc:
//   //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/geosust.png",
//   //     alt: "geosust",
//   //     description:
//   //       "Provides capability for geohazard/natural hazard risk identification and continuous monitoring, fostering proactive risk mitigation measures.",
//   //     link: "https://geosust.com/",
//   //   },
//   //   {
//   //     id: 4,
//   //     imageSrc:
//   //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/geocoast.png",
//   //     alt: "geocoast",
//   //     description:
//   //       "Provides coastal zone deterioration tracking and oil spill impact assessment, facilitating data-driven strategy implementation for coastal resilience and alignment with ESG investment priorities.",
//   //   },
//   // ];

//   const handleCardMouseEnter = (index) => {
//     switch (index) {
//       case 1:
//         setIsHovered1(true);
//         break;
//       case 2:
//         setIsHovered2(true);
//         break;
//       case 3:
//         setIsHovered3(true);
//         break;
//       case 4:
//         setIsHovered4(true);
//         break;
//       case 5:
//         setIsHovered5(true);
//         break;
//       case 6:
//         setIsHovered6(true);
//         break;
//       default:
//         break;
//     }
//   };

//   const handleCardMouseLeave = (index) => {
//     switch (index) {
//       case 1:
//         setIsHovered1(false);
//         break;
//       case 2:
//         setIsHovered2(false);
//         break;
//       case 3:
//         setIsHovered3(false);
//         break;
//       case 4:
//         setIsHovered4(false);
//         break;
//       case 5:
//         setIsHovered5(false);
//         break;
//       case 6:
//         setIsHovered6(false);
//         break;
//       default:
//         break;
//     }
//   };
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   useEffect(() => {
//     if (currentImageIndex < images.length - 1) {
//       const interval = setInterval(() => {
//         setCurrentImageIndex(prevIndex => prevIndex + 1);
//       }, 500);
//       return () => clearInterval(interval);
//     }
//   }, [currentImageIndex]);
//   return (
//     <>
//    <div className="banner-home">
//       <div>
//         <a href="#scroll-section" style={{ textDecoration: "none" }}>
//           <p className="scroll-styles">SCROLL</p>
//         </a>
//       </div>
//       <div>
//       <h1 style={{marginLeft:'10px'}} className="main-heading">
//       we restore the degraded landscapes for sustainable living and thriving businesses
//      </h1>
//      <div className="images-container">
//           {images.map((image, index) => (
//             <img
//               key={index}
//               src={image}
//               alt="step"
//               className={`image-alignment ${index === currentImageIndex ? 'visible' : ''} ${index === 4 ? 'fifth-image' : ''}`}
//             />
//           ))}
//           <img
//             src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/5_steps_vertical1.svg"
//             alt="mobile step"
//             className="mobile-image"
           
//           />
//         </div>
//         <div className="button-container">
//           <a href="/ContactUs" className="contact-button">Contact Us</a>
//         </div>
//       </div>


//     </div>

//     <div id="scroll-section" className="map-main-container">
//   <div>
//     <h2
//       style={{
//         color: "#bd0324",
//         textAlign: "center",
//         fontSize: "20px",
//         fontWeight: "bold",
//         paddingTop: "1.5rem",
//       }}
//     >
//        who are we
//     </h2>
//     <h2
//       style={{
//         fontSize: "20px",
//         textAlign: "center",
//         padding: "20px",
//         marginTop: "20px",
//         lineHeight: "1.5",
//       }}
//     >
//       At GCRS, we stand at the forefront of innovation and expertise, dedicated to tackling the complex challenges of environmental sustainability.
//       Our expert team not only offers exceptional consultancy and advisory services but also develops cutting-edge solutions and tools tailored to the unique needs of our diverse clientele. This includes government bodies, corporations, academic and research institutions, and a range of organisations committed to global sustainability, such as multi-lateral and bilateral funding partners, donor agencies, and non-governmental organisations.
//     </h2>
//     <h2
//       style={{
//         color: "#bd0324",
//         textAlign: "center",
//         fontSize: "20px",
//         fontWeight: "bold",
//       }}
//     >
//       our approach
//     </h2>
//     <div className="row justify-content-center">
//       {/* Card 1 */}
//       <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <div
//           className={`container1 ${isClicked1 ? "hovered" : ""}`}
//           onMouseEnter={() => handleCardMouseEnter(1)}
//           onMouseLeave={() => handleCardMouseLeave(1)}
//         >
//           <div className={`circle ${isHovered1 ? "hovered" : ""}`}>
//             <img
//               src={isClicked1 ? isHovered1 : normalImageSrc1}
//               style={{ width: "50px" }}
//               alt="Climate & Natural Hazard Risk Analytics"
//               className="white-card"
//             />
//           </div>
//           <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",position: "relative",display: "inline-block"}}className="hover-effect-home">
//           <span style={{fontWeight: "bold",fontSize: "16px"}}> Screening & Diagnosing</span> the Health of landscapes
//           </h3>
//         </div>
//       </div>

//       {/* Card 2 */}
//       <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <div
//           className={`container1 ${isClicked2 ? "hovered" : ""}`}
//           onMouseEnter={() => handleCardMouseEnter(2)}
//           onMouseLeave={() => handleCardMouseLeave(2)}
//         >
//           <div className={`circle ${isHovered2 ? "hovered" : ""}`}>
//             <img
//               src={isClicked2 ? isHovered2 : normalImageSrc2}
//               style={{ width: "50px" }}
//               alt="Environmental Impact & Sustainability Analytics"
//               className="white-card"
//             />
//           </div>
//           <h3 
//           style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}
//            className="hover-effect-home"
//              >
//            Detailed
//             <span style={{ fontWeight: "bold", fontSize: "16px" }}> Scientific Assessment</span>
  
// </h3>
//           </div>
//       </div>

//       {/* Card 3 */}
//       <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <div
//           className={`container1 ${isClicked3 ? "hovered" : ""}`}
//           onMouseEnter={() => handleCardMouseEnter(3)}
//           onMouseLeave={() => handleCardMouseLeave(3)}
//         >
//           <div className={`circle ${isHovered3 ? "hovered" : ""}`}>
//             <img
//               src={isClicked3 ? isHovered3 : normalImageSrc3}
//               style={{ width: "50px" }}
//               alt="Ground Water Impact Assessment"
//               className="white-card"
//             />
//           </div>
//           <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
//            <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Design</span> Restoration & Management Plans
//           </h3>
//         </div>
//       </div>

//       {/* Card 4 */}
//       <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <div
//           className={`container1 ${isClicked4 ? "hovered" : ""}`}
//           onMouseEnter={() => handleCardMouseEnter(4)}
//           onMouseLeave={() => handleCardMouseLeave(4)}
//         >
//           <div className={`circle ${isHovered4 ? "hovered" : ""}`}>
//             <img
//               src={isClicked4 ? isHovered4 : normalImageSrc4}
//               style={{ width: "50px" }}
//               alt="Card 4"
//               className="white-card"
//             />
//           </div>
//           <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
//            <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Restoration</span> of Landscapes
//           </h3>
//           </div>
//       </div>

//       {/* Card 5 */}
//       <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <div
//           className={`container1 ${isClicked5 ? "hovered" : ""}`}
//           onMouseEnter={() => handleCardMouseEnter(5)}
//           onMouseLeave={() => handleCardMouseLeave(5)}
//         >
//           <div className={`circle ${isHovered5 ? "hovered" : ""}`}>
//             <img
//               src={isClicked5 ? isHovered5 : normalImageSrc5}
//               style={{ width: "50px" }}
//               alt="Geospatial Tech Integrations"
//               className="white-card"
//             />
//           </div>
//           <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
//              Continuous <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Monitoring</span> of Landscapes
//           </h3>

//         </div>
//       </div>
//     </div>
//     <div>
//                 {/* <h2
//                   style={{
//                     color: "#BD0324",
//                     fontSize: "1.25rem",
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   our solutions
//                 </h2>
//                 <div className="image-container">
//                   <div className="row justify-content-center">
//                     {products.map((product) => (
//                       <div
//                         key={product.id}
//                         className="col-lg-3 col-md-3 col-sm-6 mb-4"
                        
//                       >
//                         <div
//                           style={{
//                             height: "250px",
//                             width: "100%",
//                             borderRadius: "10px",
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                           }}
//                         >
//                           <a className="fusion-no-lightbox" href={product.link}>
//                             <img
//                               decoding="async"
//                               src={product.imageSrc}
//                               alt={product.alt}
//                               style={{
//                                 width: "14.375rem",
//                                 height: "5rem",
                                
//                               }}
//                               className="img-fluid"
//                             />
//                           </a>
//                           <p
//                             style={{
//                               color: "#282222",
//                               fontSize: "0.875rem",

//                               fontWeight: "bold",
                              
                             
//                             }}
//                           >
//                             {product.description}
//                           </p>
//                           {product.link && (
//                             <a
//                               href={product.link}
//                               id="accred_link"
//                               target="_blank"
//                               rel="noopener noreferrer"
//                               style={{}}
//                             >
//                               ({product.link})
//                             </a>
//                           )}
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div> */}
//     <div className="parent">
//       {/* Header content */}
//       <div className="header-content">
//         <button
//           className="BannerToggleButton"
//           onClick={handleToggleDetails}
//         >
//           {showBar ? (
//             <i className="fas fa-times"></i>
//           ) : (
//             <i className="fas fa-bars"></i>
//           )}
//         </button>
//         <h1
//           className="dashboard-text"
//           style={{
//             color: "#bd0324",
//             textAlign: "center",
//             fontSize: "20px",
//             fontWeight: "bold",
//             marginRight:'40px'
//           }}
//         >
//           our presence
//         </h1>
//       </div>

//       {/* Map and other content */}
//       <div className="content-container" style={{ marginBottom: '50px' }}>
//         {/* SidebarDashboard */}
//         {showBar && (
//           <SidebarDashboard
//             handleCheckboxChange={handleCheckboxChange}
//             checkCategory={checkCategory}
//             className="sidebar-dashboard"
//           />
//         )}

//         {/* OpenLayersMap */}
//         <OpenLayersMap
//           showBar={showBar}
//           selectedOptions={selectedOptions}
//           checkCategory={checkCategory}
//         />
//       </div>
//     </div>
//   </div>
// </div>
// </div>


//     </>
//   );
// };
// export default Home;
