import React, { useState ,useEffect,useRef} from "react";
import "./header.css";
import { HiX, HiMenu } from "react-icons/hi";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCloseButtonTouched, setIsCloseButtonTouched] = useState(false);
  const [isCloseButtonHovered, setIsCloseButtonHovered] = useState(false);
  const location = useLocation();
  const isOurJourneyOpen = location.pathname === "/ourJourney";
  const isPeopleOpen = location.pathname === "/people";
  const isClientsOpen = location.pathname === "/clients";
  const isNewsOpen = location.pathname === "/news";
  const isInvestorsPartners = location.pathname === "/InvestorsPartners";
  const isNotFoundPage = location.pathname === "/NotFoundPage";
  const isTemplate = location.pathname === "/waterstewardship";
  const isTemplateLamas = location.pathname === "/lamas";
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const contentRef = useRef(null);
  const [isSolutionsHovered, setIsSolutionsHovered] = React.useState(false);
const [isButtonHovered, setIsButtonHovered] = React.useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const toggleExpand = () => setIsExpanded(!isExpanded);
  const closeDropdown = () => setIsExpanded(false);
  const dropdownRef = useRef(null);
  

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);
  
  const routeMap = {
    'Water Stewardship': '/waterstewardship',
    'LAMAS': '/lamas',
    'Carbon Exchange': '/carbonexchange',
    'GeoSust': '/geosust',
    'Geo Hazard': '/geohazard',
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    closeDropdown();
    
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getFontSize = () => {
    if (window.innerWidth <= 480) return '8px';
    if (window.innerWidth <= 768) return '8px';
    return '19px';
  };
  
  const toggleSideNav = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsCloseButtonTouched(!isCloseButtonTouched);
    setIsCloseButtonHovered(false);
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMenuOpen]);
  return (
    <div>
     <nav
        className={`bg-nav-bar sticky-header ${
          isScrolled ? "navbar-active" : ""
        }`}
        
      >
      {!isMenuOpen && (
  <div className="logo-container">
    <NavLink to="/">
      <img
        src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/GCRSLOgo.svg"
        alt="Logo"
        className="logo"
      />
    </NavLink>

    
  </div>
)}
{!isMenuOpen && (
  <div className="menu-container">
    <NavLink
      to="/aboutUs"
      className="nav-link"
      style={{
        color:
          isScrolled ||
          isOurJourneyOpen ||
          isPeopleOpen ||
          isClientsOpen ||
          isNewsOpen ||
          isInvestorsPartners ||
          isNotFoundPage ||
          isTemplate ||
          isTemplateLamas
            ? "black"
            : "white",
      }}
      onMouseEnter={(e) => (e.target.style.color = "#bd0324")}
      onMouseLeave={(e) =>
        (e.target.style.color =
          isScrolled ||
          isOurJourneyOpen ||
          isPeopleOpen ||
          isClientsOpen ||
          isNewsOpen ||
          isInvestorsPartners ||
          isNotFoundPage ||
          isTemplate ||
          isTemplateLamas
            ? "black"
            : "white")
      }
    >
      About Us
    </NavLink>

    <div ref={dropdownRef} className="dropdown-container">
      <div
        
        onMouseEnter={() => {
          setIsSolutionsHovered(true);
          setIsButtonHovered(true);
        }}
        onMouseLeave={() => {
          setIsSolutionsHovered(false);
          setIsButtonHovered(false);
        }}
      >
   <div className="dropdown-container">
  <NavLink
    onClick={toggleExpand}
    className="nav-link"
    style={{
      fontSize: getFontSize(),
      color: isSolutionsHovered
        ? "#bd0324"
        : isScrolled ||
          isOurJourneyOpen ||
          isPeopleOpen ||
          isClientsOpen ||
          isNewsOpen ||
          isInvestorsPartners ||
          isNotFoundPage ||
          isTemplate ||
          isTemplateLamas
          ? "black"
          : "white",
    }}
  >
    Solutions
  </NavLink>
  <button
    onClick={toggleExpand}
    className="dropdown-button"
    style={{
      color: isSolutionsHovered
        ? "#bd0324"
        : isScrolled ||
          isOurJourneyOpen ||
          isPeopleOpen ||
          isClientsOpen ||
          isNewsOpen ||
          isInvestorsPartners ||
          isNotFoundPage ||
          isTemplate ||
          isTemplateLamas
        ? "black"
        : "white",
      fontSize: getFontSize(),
    }}
  >
    {isExpanded ? "-" : "+"}
  </button>
</div>

      </div>

      <div
  ref={contentRef}
  className={`dropdown-content ${isExpanded ? "expanded" : ""} ${
    isScrolled ||
    isOurJourneyOpen ||
    isPeopleOpen ||
    isClientsOpen ||
    isNewsOpen ||
    isInvestorsPartners ||
    isNotFoundPage ||
    isTemplate ||
    isTemplateLamas
      ? "white-bg"
      : ""
  }`}
>
  <ul className="dropdown-list">
    {Object.keys(routeMap).map((item, index) => (
      <li key={index} className="dropdown-list-item">
        <NavLink
          to={routeMap[item]}
          onClick={closeDropdown}
          className={
            location.pathname === routeMap[item] ? "active" : ""
          }
        >
          {item}
        </NavLink>
      </li>
    ))}
  </ul>
</div>

    </div>

    <NavLink
      to="/contactUs"
      className="nav-link"
      style={{
        color:
          isScrolled ||
          isOurJourneyOpen ||
          isPeopleOpen ||
          isClientsOpen ||
          isNewsOpen ||
          isInvestorsPartners ||
          isNotFoundPage ||
          isTemplate ||
          isTemplateLamas
            ? "black"
            : "white",
      }}
      onMouseEnter={(e) => (e.target.style.color = "#bd0324")}
      onMouseLeave={(e) =>
        (e.target.style.color =
          isScrolled ||
          isOurJourneyOpen ||
          isPeopleOpen ||
          isClientsOpen ||
          isNewsOpen ||
          isInvestorsPartners ||
          isNotFoundPage ||
          isTemplate ||
          isTemplateLamas
            ? "black"
            : "white")
      }
    >
      Contact Us
    </NavLink>
  </div>
)}

        
        <NavLink
          to="#"
          className={`menu-button ${
            isCloseButtonTouched ? "close-touched" : ""
          }`}
          onClick={toggleSideNav}
          onMouseEnter={() => setIsCloseButtonHovered(true)}
          onMouseLeave={() => setIsCloseButtonHovered(false)}
        >
          {isMenuOpen ? (
            <HiX
              size={30}
              style={{ color: isCloseButtonHovered ? "#bd0324" : "black" }}
            />
          ) : (
            <HiMenu
              size={30}
              style={{
                color:
                  isScrolled ||
                  isOurJourneyOpen ||
                  isPeopleOpen ||
                  isClientsOpen ||
                  isNewsOpen ||
                  isInvestorsPartners ||
                  isNotFoundPage ||
                  isTemplate ||
                  isTemplateLamas
                    ? isCloseButtonHovered
                      ? "#bd0324"
                      : "#000"
                    : isCloseButtonHovered
                    ? "#bd0324"
                    : "#fff",
                transition: "color 0.3s ease",
              }}
              
            />
          )}
        </NavLink>
      </nav>
      {isMenuOpen && (
        <>
          <div className="overlay-header" onClick={toggleSideNav}></div>
          <div className="side-nav side-nav-open">
            <NavLink
              to="#"
              className={`menu-button ${
                isCloseButtonTouched ? "close-touched" : ""
              }`}
              onClick={toggleSideNav}
              onMouseEnter={() => setIsCloseButtonHovered(true)}
              onMouseLeave={() => setIsCloseButtonHovered(false)}
            >
              <HiX size={30} />
            </NavLink>
            <div className="scrollable-nav">
              <NavLink
              
                to="/"
                onClick={toggleSideNav}
                style={{ paddingTop: "10px" }}
              >
                Home
              </NavLink>
              <NavLink to="/aboutUs" onClick={toggleSideNav}>
                About Us
              </NavLink>
              <NavLink to="/people" onClick={toggleSideNav}>
                People
              </NavLink>
              <NavLink to="/capabilities" onClick={toggleSideNav}>
                Capabilities
              </NavLink>
              <NavLink to="/projects" onClick={toggleSideNav}>
                Projects & Case Studies
              </NavLink>
              <NavLink to="/products" onClick={toggleSideNav}>
                Solutions
              </NavLink>
              <NavLink to="/ourJourney" onClick={toggleSideNav}>
                Our Journey
              </NavLink>
              <NavLink to="/" style={{ color: "gray" }}>
                __
              </NavLink>
              <NavLink to="/clients" onClick={toggleSideNav}>
                <span className="hover-effect-header">Our Investors & Partners & Clients</span>
              </NavLink>
              {/* <NavLink to="/InvestorsPartners" onClick={toggleSideNav}>
                <span className="hover-effect-header">
                  Our Investors & Partners
                </span>
              </NavLink> */}
              <NavLink to="/commitments" onClick={toggleSideNav}>
                <span className="hover-effect-header">Commitments</span>
              </NavLink>
              <NavLink to="/randDintiatives" onClick={toggleSideNav}>
                <span className="hover-effect-header">R&D Initiatives</span>
              </NavLink>
              <NavLink to="/careers" onClick={toggleSideNav}>
                <span className="hover-effect-header">Careers</span>
              </NavLink>

              <NavLink to="/news" onClick={toggleSideNav}>
                <span className="hover-effect-header">News</span>
              </NavLink>
              <NavLink to="/contactUs" onClick={toggleSideNav}>
                <span className="hover-effect-header">Contact Us</span>
              </NavLink>
              
              <NavLink
                to="/NotFoundPage"
                onClick={toggleSideNav}
                style={{ display: "none" }}
              >
                <span className="hover-effect-header"></span>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Header;
