import React from "react";


const useResponsiveStyles = () => {
  const [styles, setStyles] = React.useState(
    window.innerWidth > 768 ? largeScreenStyles : mobileStyles
  );

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setStyles(largeScreenStyles);
      } else {
        setStyles(mobileStyles);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return styles;
};
const largeScreenStyles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0",
    gap: "20mm",
    height: "auto",
    overflow: "hidden",
  },
  page: {
    width: "100%",
    maxWidth: "280mm",
    height: "auto",
    padding: "5%",
    backgroundColor: "#ffffff",
    color: "#333333",
    fontFamily: "Verdana, sans-serif",
    fontSize: "1rem",
    lineHeight: "1.6",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  page2: {
    width: "100%",
    maxWidth: "280mm",
    padding: "20mm",
    backgroundColor: "#ffffff",
    color: "#333333",
    fontFamily: "Verdana, sans-serif",
    fontSize: "12pt",
    lineHeight: "1.6",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#125d81",
    marginBottom: "20px",
  },
  imageText: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end",
    color: "#125d81",
    marginBottom: "20px",
    fontSize: "10pt",
    fontWeight: "bold",
  },
  image: {
    width: "140px",
    height: "auto",
  },
  subHeader: {
    textAlign: "start",
    color: "#125d81",
    fontSize: "16pt",
    marginBottom: "15px",
  },
  subHeader2: {
    textAlign: "start",
    color: "#125d81",
    fontSize: "16pt",
    marginBottom: "15px",
    fontWeight: "bold",
  },
  imageSection: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  image1: {
    maxWidth: "100%",
    height: "auto",
  },
  contentSection: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "1rem",
    marginTop: "20px",
  },
  contentColumn: {
    flex: "1 1 calc(50% - 1rem)",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  footer: {
    textAlign: "center",
    borderTop: "1px solid #333",
    paddingTop: "10px",
    marginTop: "20px",
    fontSize: "10pt",
    color: "#666666",
  },
  contactInfo: {
    textAlign: "center",
    marginTop: "10px",
    fontSize: "10pt",
    color: "#333333",
  },
  container: { padding: '20px', fontFamily: 'Arial, sans-serif' },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    backgroundColor: '#ffff', 
    color: 'black',
    padding: '10px',
    border: '1px solid #000',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  td: {
    border: '1px solid #000',
    padding: '10px',
    fontSize: '14px',
    verticalAlign: 'top',
  },
  header3: {
    backgroundColor: '#ffff', 
    fontWeight: 'bold',
  },
  withinFence: {
    backgroundColor: '#ffcc66', 
  },
  beyondFence: {
    backgroundColor: '#8db3e2', 
  },
  keyBenefits: {
    backgroundColor: '#ffff', 
  },
  sectionIcon: {
    marginRight: '10px',
    width:"100px"
  },
};
const mobileStyles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0",
    gap: "20mm",
    height: "auto", 
    overflow: "hidden",
  },
  page: {
    width: "100%",
    maxWidth: "280mm",
    height: "auto", 
    padding: "5%",
    backgroundColor: "#ffffff",
    color: "#333333",
    fontFamily: "Verdana, sans-serif",
    fontSize: "1rem",
    lineHeight: "1.6",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#125d81",
    marginBottom: "1rem",
    flexWrap: "wrap",
    marginTop:"70px",
    marginLeft:'10px'
  },
  imageText: {
    textAlign: "center",
    color: "#125d81",
    marginBottom: "1rem",
    fontSize: "0.8rem",
    fontWeight: "bold",
   
  },
  image: {
    maxWidth: "140px",
    width: "100%",
    height: "auto",
  },
  subHeader: {
    textAlign: "center",
    color: "#125d81",
    fontSize: "1.2rem",
    marginBottom: "1rem",
  },
  subHeader2: {
    textAlign: "center",
    color: "#125d81",
    fontSize: "1.0rem",
    marginBottom: "1rem",
    fontWeight: "bold",
  },
  imageSection: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  image1: {
    maxWidth: "90%",
    height: "auto",
  },
  contentSection: {
    marginBottom: "1rem",
    
    marginLeft:"1rem"
    
  },
  footer: {
    textAlign: "center",
    borderTop: "1px solid #333",
    paddingTop: "0.5rem",
    marginTop: "1rem",
    fontSize: "0.8rem",
    color: "#666666",
  },
  contactInfo: {
    textAlign: "center",
    marginTop: "10px",
    fontSize: "10pt",
    color: "#333333",
  },
  container: {    width: '100%',
    overflowX: 'auto', 
    margin: '0.5rem 0', 
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    backgroundColor: '#ffff',
    color: 'black',
    padding: '8px',
    border: '1px solid #000',
    fontSize: '14px', 
    fontWeight: 'bold',
    textAlign: 'left',
  },
  td: {
    border: '1px solid #000',
    padding: '8px',
    fontSize: '12px', 
    verticalAlign: 'top',
  },
  header3: {
    backgroundColor: '#ffff', 
    fontWeight: 'bold',
  },
  withinFence: {
    backgroundColor: '#ffcc66', 
  },
  beyondFence: {
    backgroundColor: '#8db3e2', 
  },
  keyBenefits: {
    backgroundColor: '#ffff', 
  },
  sectionIcon: {
    marginRight: '10px',
    width:"100px"
  },
}

const A4Template = () => {
  const styles = useResponsiveStyles();







  return (
    <div style={{ ...styles.wrapper, marginTop: "60px" }}>
    {/* Page 1 */}
    <div style={{ ...styles.page2, marginBottom: "20px" }}>
      <div>
        <div style={styles.header}>
          <span style={{ fontSize: "9pt" }}>
            Your Sustainability Partner in <strong>Water Risk Management and Water Stewardship Journey</strong>
          </span>
          <img
            src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/GCRSLOgo.svg"
            alt="Logo"
            style={styles.image}
          />
        </div>
  
        <div style={styles.subHeader}>
          GCRS - Your <span style={{ fontWeight: 'bold' }}>Sustainability</span> Partner in
          <div>
            <span style={{ fontWeight: 'bold' }}>Water Risk Management</span> & 
            <span style={{ fontWeight: 'bold' }}>Water Stewardship</span> Journey
          </div>
        </div>
  
        <div style={styles.imageSection}>
            <img
              src={require("../../src/assets/gcrs_images/template/latha.jpg")}
              alt="Water Stewardship"
              style={styles.image1}
            />
          </div>
  
        <section style={{ ...styles.contentSection, display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <div style={{ width: "48%", display: "flex", flexDirection: "column", marginBottom: "20px" }}>
            <p style={{color:"black"}}>
            Water is the new currency. It is a key
            resource in everyday industrial operations
            (within the fence) and shared resources
            (beyond the fence). To maintain availability
            of freshwater resources in the industry's
            catchment area and considering the
            community's right towards water use from
            the catchment, industries are taking
            significant steps towards developing water
            conservation, rainwater harvesting and
            recharge capabilities.
            </p>
            <p style={{ marginTop: "60px" }}>
            Industries are increasingly investing in
          water sustainability and stewardship
          initiatives to achieve a water-positive
          footprint across all operational zones. 
              <span style={{ fontWeight: "bold", color: "#000000" }}>
              The
            core challenge lies in reliable risk
            assessment and scientific modeling,
            which are essential for effective
            planning and precise impact
            assessment to determine the Return
            on Investment (ROI).
              </span>
            </p>
          </div>
  
          <div style={{ width: "48%", display: "flex", flexDirection: "column", marginBottom: "20px" }}>
            <p style={{ fontWeight: "bold", color: "#000000", fontStyle: "italic" }}>
            GCRS offers comprehensive solutions
            to help industries map, measure, and
            model water risks, and prepare
            roadmap towards water positivity and
            sustainable stewardship.
            </p>
            <p style={{color:"black"}}>
            We have successfully executed water risk
and sustainability analysis, and water
stewardship road map for the following
sector: 
            </p>
            <img 
              src={require("../../src/assets/gcrs_images/template/template2.png")} 
              alt="Water Stewardship" 
              style={styles.image1}
            />
            <p style={{ wordWrap: "break-word" }}>
            Cement | Food Processing Beverages | IT
Sector | Power & Utilities | Oil & Gas |
Automotive | Textile | Chemicals and
Fertilizers |Paper & Pulp | Metals and Mining
| Airports | Pharmaceuticals |Infrastructure
| Hydropower | Drinking Water | Agriculture 
            </p>
          </div>
        </section>
      </div>
  
      {/* <div style={styles.footer}>
        <p style={{color:"black"}}>
          Email: <a href="mailto:business@gcrs.co.in">business@gcrs.co.in</a> ; 
          Phone: 
          <a href="tel:+919810708901">+91 9810708901</a> / 
          <a href="tel:+917583892688">+91 7583892688</a> / 
          <a href="tel:+918587998798">+91 8587998798</a>; 
          Website: <a href="https://gcrs.co.in/" target="_blank" rel="noopener noreferrer">https://gcrs.co.in/</a>
        </p>
        
        <p style={{color:"black"}}>Geo Climate Risk Solutions Pvt Ltd (CIN No.: U74930AP2014PTC095735 & GSTIN No.: 37AAFCG8137J1Z9)</p>
      </div> */}
    </div>
  
    {/* page2 */}
    <div style={{ ...styles.page, marginBottom: "10px" }}>
      <div>
        
  
        <div style={styles.subHeader2}>
          How you benefit from our solutions! 
        </div>
  
        <img 
          src={require("../../src/assets/gcrs_images/template/waterstewardship_strip.png")} 
          alt="Water Stewardship" 
          style={styles.image1}
        />
        <p style={styles.imageText}>(Source of assessment unit concept has taken from CII- Triveni Water Institute)</p>
        <div style={styles.container}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>GCRS Delivery Model</th>
            <th style={{ ...styles.th, backgroundColor: styles.withinFence.backgroundColor }}>Within the fence (5/10km buffer zone)</th>
            <th style={{ ...styles.th, backgroundColor: styles.beyondFence.backgroundColor }}>Beyond the Fence</th>
            <th style={{ ...styles.th, backgroundColor: styles.keyBenefits.backgroundColor }}>Key benefits</th>
          </tr>
        </thead>
        <tbody>
          <tr style={styles.header3}>
            <td style={styles.td}>
              <img src={require("../../src/assets/gcrs_images/template/screening.png")}  alt="Screening & Diagnosing" style={styles.sectionIcon} />
             
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.withinFence.backgroundColor }}>
              <ul>
                <li>Rapid Water Risk Analysis</li>
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.beyondFence.backgroundColor }}>
              <ul>
                <li>Rapid Water Risk Analysis</li>
                <li>Land use patterns</li>
                <li>Climate Risk</li>
                <li>Biodiversity Risk</li>
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.keyBenefits.backgroundColor }}>
              ✓ Water risk analysis reporting within and beyond the fence
            </td>
          </tr>

          {/* second  */}
          <tr style={styles.header3}>
            <td style={styles.td}>
              <img src={require("../../src/assets/gcrs_images/template/rootCauseAnalysis.png")}  alt="Screening & Diagnosing" style={styles.sectionIcon} />
             
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.withinFence.backgroundColor }}>
              <ul>
                <li>Groundwater Impact
                Assessment Report</li>
                <li>Comprehensive
                Hydrogeological Report</li>
                <li>Groundwater Modelling</li>
                <li>Surface-Runoff Modelling</li>
                <li>Stormwater drainage
                system analysis</li>
                <li>Water Balance
                assessment</li>
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.beyondFence.backgroundColor }}>
              <ul>
                <li>Impact on Groundwater
                regimes</li>
                <li>Ground Water Recharge
                potential zones</li>
                <li>Surface Water
conservation potential
locations.</li>
                <li>Surface runoff modelling
                & analysis</li>
                <li>Flood risk analysis</li>
                <li>Climate risk analysis at
                catchment scale</li>
                <li>Comprehensive
                hydrology report.</li>
              </ul>
            </td>
            <td style={{ 
  ...styles.td, 
  backgroundColor: styles.keyBenefits.backgroundColor 
}}>
  ✓ NoC from CGWA <br />
  ✓ Water Audit Certificate <br />
  ✓ Details surface and
groundwater risk analysis
reports<br />
  ✓ Enhance CSR activities.
  <br />
  ✓ Meet the regulatory
  compliance (CGWA/CGWB)
  <br />
  ✓ Potential zones for
  conservation and recharge
  
</td>

          </tr>
          {/* third */}
          <tr style={styles.header3}>
            <td style={styles.td}>
              <img src={require("../../src/assets/gcrs_images/template/Design.png")}  alt="Screening & Diagnosing" style={styles.sectionIcon} />
             
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.withinFence.backgroundColor }}>
              <ul>
                <li> Rainwater harvesting
                designs</li>
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.beyondFence.backgroundColor }}>
              <ul>
                <li>Water conservation &
Artificial recharge
structural design.</li>
                <li> Water Management plan</li>
               
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.keyBenefits.backgroundColor }}>
              ✓ Nature based designs for
              Lake/Ponds rejuvenation
              <br />
  ✓ Road map for water positivity
            </td>
          </tr>
           {/* 4th */}
           <tr style={styles.header3}>
            <td style={styles.td}>
              <img src={require("../../src/assets/gcrs_images/template/restorationofwatreshed.png")}  alt="Screening & Diagnosing" style={styles.sectionIcon} />
             
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.withinFence.backgroundColor }}>
              <ul>
                <li>Rainwater harvesting &
                recharge</li>
                
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.beyondFence.backgroundColor }}>
              <ul>
                <li>Water conservation and
                recharge
</li>
                <li>Lake/Pond rejuvenation</li>
                
               
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.keyBenefits.backgroundColor }}>
              ✓ Increase Supply-side sources
              <br />
  ✓ Achieved Water
positivity/neutrality for
sustainability
  <br />
  ✓ Enhance water sustainability
rating, fulfilling ESG & SDG
goals
            </td>
          </tr>
          {/* 5th */}
          <tr style={styles.header3}>
            <td style={styles.td}>
              <img src={require("../../src/assets/gcrs_images/template/monitoring.png")}  alt="Screening & Diagnosing" style={styles.sectionIcon} />
             
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.withinFence.backgroundColor }}>
              <ul>
                <li> Groundwater levels</li>
                <li> Water flow metering</li>
                <li> ETP, STP and WTP</li>
                <li>Water Balance
                Monitoring</li>
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.beyondFence.backgroundColor }}>
              <ul>
                <li>Surface water bodies
                health
</li>
                <li>Measure and monitoring
                CSR activity impacts</li>
                <li>Catchment scale
                monitoring</li>
               
              </ul>
            </td>
            <td style={{ ...styles.td, backgroundColor: styles.keyBenefits.backgroundColor }}>
              ✓Increase water use efficiency
and reduce the operational
cost
              <br />
  ✓ Enhance the profitability
  <br />
  ✓ Certification from Alliance for
  Water Stewardship & CIITriveni Water Institute
            </td>
          </tr>
         
          
        </tbody>
      </table>
    </div>
        <div style={styles.subHeader2}>
          Why GCRS?
        </div>
        
        <p>
        GCRS is a landscape restoration solution provider for sustainable living and thriving businesses. Through
digital solutions, modelling and assessments, GCRS helps companies to achieve water sustainability and
stewardship. Our team will help industries to qualify for Green Credits to promote water conservation, water
harvesting, and water use efficiency or water savings, including treatment and reuse of wastewater schemes
within the fence and outside the fence. GCRS is an accredited company by Central Ground Water Authority
(CGWA), Ministry of Jal Shakti, Government of India, Certificate No.: CGWA/RGI/005. 
        </p>
      </div>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <a
  href={require("../../src/assets/gcrs_images/template/GCRSYourSustainabilityPartnerinStewardshipJourneyy.pdf")}
  id="accred_link"
  target="_blank"
  rel="noopener noreferrer"
  style={{ color: "#125d81", fontWeight: "bold" }}
>
  Click here to Download
</a>

      </div>
  
      <div style={styles.footer}>
        <p style={{color:"black"}}>
          Email: <a href="mailto:business@gcrs.co.in">business@gcrs.co.in</a> ; 
          Phone: 
          <a href="tel:+919810708901">+91 9810708901</a> / 
          <a href="tel:+917583892688">+91 7583892688</a> / 
          <a href="tel:+918587998798">+91 8587998798</a>; 
          Website: <a href="https://gcrs.co.in/" target="_blank" rel="noopener noreferrer">https://gcrs.co.in/</a>
        </p>
        
        <p style={{color:"black"}}>Geo Climate Risk Solutions Pvt Ltd (CIN No.: U74930AP2014PTC095735 & GSTIN No.: 37AAFCG8137J1Z9)</p>
      </div>
    </div>
   
  </div>
  
  );
};


export default A4Template;
