import React from "react";
import "./News.css";

const News = () => {
  return (
    <div className="bg-news">
      <h1 className="main-heading1">News</h1>
      <h1 className="sub-heading1">
        Industry news, discussion topics and what we are doing
       to make a positive difference to the world we all live in
      </h1>
      <div className="image-con">
        <a href="https://twitter.com/gailindia/status/1123883594844393473">
          <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/news/news_1.png" />
        </a>
        
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 141.png" />
        <img src={require('../../src/assets/gcrs_images/news/news_1.png')}/>
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 144.png" />

        <img src={require('../../src/assets/gcrs_images/news/news_22.png')}/>
        <img src={require('../../src/assets/gcrs_images/news/news33.png')}/>
        <img src={require('../../src/assets/gcrs_images/news/news44.png')}/>
        
        {/* 
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 142.png" /> */}
        {/* <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 144.png" />
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 145.png" /> */}
        <a href="http://magazines.insightssuccess.in/The-10-Most-Recommended-GIS-Solution-Provider-Companies-September2019/#page=32">
          <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/news/news_2.png" />
        </a>
        
        <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ourJourney/Rectangle 142.png" />
        <a href="https://www.deccanchronicle.com/southern-states/andhra-pradesh/au-start-up-offers-climate-risk-solutions-1841612">
          <img src={require('../../src/assets/gcrs_images/news/updated_news.jpeg')} style={{height:'390px',width:'450px'}}/>
        </a>
        
      </div>
    </div>
  );
};

export default News;
